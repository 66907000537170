<template>
  <div>
    <div v-if="!items">{{ msgLoading }}</div>
    <div v-if="typeof items === 'object'">
      <div v-if="items && !Object.keys(items).length">{{ msgEmpty }}</div>
      <div v-else-if="items && items.error">
        {{ msgError }} {{ items.error }}
      </div>
    </div>
    <div v-else-if="Array.isArray(items)">
      <div v-if="items && !items.length">{{ msgEmpty }}</div>
    </div>
    <div v-else-if="msgLoaded">
      {{ msgLoaded }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: [Object, Array, String],
      default: null,
    },
    msgLoading: {
      type: String,
      default: "Načítavam...",
    },
    msgEmpty: {
      type: String,
      default: "Nenachádzajú sa tu žiadne položky",
    },
    msgError: {
      type: String,
      default: "Nastala chyba: ",
    },
    msgLoaded: {
      type: String,
      default: "",
    },
  },
};
</script>
